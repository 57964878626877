import React from "react";
import styled from "styled-components";
import firstIcon from "assets/svg/01.svg";
import { CustomButton } from "components/common";

const SolutionCards = ({
  solutionIcon,
  solutionTitle,
  SolutionDesc,
  showComingSoon,
}) => {
  return (
    <Wrapper>
      <div className="climate-card-section">
        <div className="climate-btn-img">
          <div>
            <img src={solutionIcon} />
          </div>
          {showComingSoon && (
            <div className="coming-soon-btn">
              <CustomButton title={"Coming Soon"} />
            </div>
          )}
        </div>
        <div className="title">{solutionTitle}</div>
        <div className="desc">{SolutionDesc}</div>
      </div>
    </Wrapper>
  );
};

export default SolutionCards;

const Wrapper = styled.div`
  background-color: #3f674e;
  border-radius: 5px;
  padding: 25px;
  height: 300px;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: translateY(-10px);
  }
  
  .climate-card-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .climate-btn-img {
    display: flex;
    justify-content: space-between;
  }
  .coming-soon-btn {
    position: relative;
    top: -8px;
  }
  .coming-soon-btn .btn {
    padding: 2px 12px;
    font-size: 12px;
  }
  .title {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    margin-top: 10px;
  }
  .desc {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    margin-top: 20px;
  }
  @media (max-width: 1250px) {
    height: 330px;
  }
  @media (max-width: 500px) {
    height: 100%;
  }
`;
